h1 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
h2 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
a {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.custom-shape-divider-top-1643206829 {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1643206829 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 182px;
}

.custom-shape-divider-top-1643206829 .shape-fill {
  fill: #f14a16;
}
.SkillSection {
  margin: 10% 2% 20% 5%;
}
.Heading {
  font-size: 70px;
  color: white;
  word-wrap: break-word;
  margin: 5% 3% 2% 5%;
}
.SkillsSubPara {
  margin: 10% 3% 2% 5%;
  font-size: 20px;
  word-wrap: break-word;
  line-height: 1;
  color: white;
}
.Cone {
  color: #ff0000;
}
.Ctwo {
  color: #fbff00;
}
.Cthree {
  color: #35013f;
}
.Cfour {
  color: #1fffff;
}
.Cfive {
  color: #000d6b;
}
.Csix {
  color: #ff0000;
}
.Cspace {
  color: #72147e;
}
.Skill {
  width: 400px;
  height: 400px;
  margin-bottom: 30%;
}
.Languages {
  margin: 20% 2% 5% 5%;
  line-height: 1;
  word-wrap: break-word;
}
.LangaugeUImage {
  width: 400px;
  height: 400px;
  margin: 10% 2% 5% 10%;
}
.Frameworks {
  margin: 15% 2% 5% 5%;
  line-height: 1;
  word-wrap: break-word;
}
.FrameworkImage {
  width: 400px;
  height: 400px;
  margin: 15% 2% 5% 10%;
}
.Skillsbar {
  margin: 5% 2% 20% 10%;
}
@keyframes load {
  from {
    width: 0%;
  }
}
@-webkit-keyframes load {
  from {
    width: 0%;
  }
}
@-moz-keyframes load {
  from {
    width: 0%;
  }
}
@-o-keyframes load {
  from {
    width: 0%;
  }
}
.SkillText {
  margin: 2% 2% 2% 2%;
  color: #fff;
}
.bar {
  background-color: #000000;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.bar::before {
  content: attr(data-skill);
  background-color: #fbff00;
  display: inline-block;
  padding: 5px 0 5px 10px;
  border-radius: inherit;
  animation: load 2s 0s;
  -webkit-animation: load 2s 0s;
  -moz-animation: load 2s 0s;
  -o-animation: load 2s 0s;
}

.bar.front::before {
  background-color: #e60965;
}
.bar.back::before {
  background-color: #f14a16;
}
.bar.colour2::before {
  background-color: #432160;
}
.bar.colour3::before {
  background-color: #1fffff;
}
.bar.colour4::before {
  background-color: #8e05c2;
}
.bar.colour5::before {
  background-color: #ff0000;
}
.bar.colour6::before {
  background-color: #35013f;
}
.bar.colour7::before {
  background-color: #000d6b;
}
.bar.learning::before {
  width: calc(20% - 10px);
}
.bar.basic::before {
  width: calc(40% - 10px);
}
.bar.intermediate::before {
  width: calc(100% - 30%);
}
.bar.advanced::before {
  width: calc(100% - 10%);
}
.bar.expert::before {
  width: calc(100% - 0%);
}
.bar.Best::before {
  width: calc(100% - 10px);
}
.bar.ametuer::before {
  width: calc(100% - 70%);
}

.custom-shape-divider-bottom-1643207379 {
  margin-top: 2%;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1643207379 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 182px;
}

.custom-shape-divider-bottom-1643207379 .shape-fill {
  fill: #e60965;
}
