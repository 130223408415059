
body{
    background: #000000;
}

.D{
    margin: 30% 3% 0% 5%; 
}
.ABoutText{
    margin: 15% 3% 3% 10%;
    font-weight: bolder;
    font-size: 70px;
    word-wrap: break-word;
}
.AboutMEOne{
    color: #A8FF3E;
}
.AboutMETwo{
    color: #FC5404 ;
}
.AboutMeThree{
    color: #610094;
}
.AboutMeFour{
    color: #DA0037;
}
.AboutMeFive{
    color: #FFD700;
}
.AboutMeSix{
    color: #F70776;
    margin-left: 2%;
}
.AboutMeSeven{
    color: #900C3F;
}
@media (max-width: 400px) {
    .ABoutText{
        word-wrap: break-word;
    }
    .AboutMePara{
        word-wrap: break-word;
    }
}
.ABoutText:hover{
    color: #6867AC;
}
.AnimatedText{
    color: #900C3F;
}
.SubText{
    margin: 10% 3% 2% 10%;
    font-weight: bold;
    font-size: 30px;
    line-height: 1;
    word-wrap: break-word;
}
.AboutMePara{
    margin: 5% 3% 2% 10%;
    font-size: 20px;
    word-wrap: break-word;
    line-height: 1;
    color: white;

}
.SubAbout{
    margin: 2% 3% 2% 10%;
    opacity: 0.5;
    font-size: 17px;
}
.AboutBlocks{
    margin: 10% 2% 2% 9%;
    z-index: 0;
}
.Blockone{
    margin: 2% 2% 1% 2%;
    padding: 2% 2% 2% 2%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: -5px -5px 10px rgba(255, 255,255,0.2),
                 15px 15px 20px rgba(0, 0,0,0.8);
    background: #F9F7F7;

}
.Blocktwo{
    margin: 2% 2% 1% 2%;
    background: #F9F7F7;
    padding: 2% 2% 2% 2%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: -5px -5px 10px rgba(255, 255,255,0.2),
    15px 15px 20px rgba(0, 0,0,0.8);
background: #F9F7F7;
}
.Blockthree{
    margin: 2% 2% 1% 2%;
    background: #F9F7F7;
    padding: 2% 2% 2% 2%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: -5px -5px 10px rgba(255, 255,255,0.2),
    15px 15px 20px rgba(0, 0,0,0.8);
    background: #F9F7F7;
}
.blockText{
    color:#F9F7F7;
    margin: 15% 2% 9% 2%;
}
.blockTextone{
    color: #F9F7F7;
    margin: 15% 2% 9% 2%;
}
.AboutIcons{
   margin: 2% 15% 2% 30%;
   color: #A8FF3E;
   width: 80px;
   height: 80px;
}


.custom-shape-divider-bottom-1642173415 {
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-top: 2%;

}

.custom-shape-divider-bottom-1642173415 svg {
    position: relative;
    display: block;
    width: calc(138% + 1.3px);
    height: 244px;
}

.custom-shape-divider-bottom-1642173415 .shape-fill {
    fill: #FF008E;
}



.custom-shape-divider-top-1642173651 {
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 0;
}

.custom-shape-divider-top-1642173651 svg {
    position: relative;
    display: block;
    width: calc(145% + 1.3px);
    height: 142px;
}

.custom-shape-divider-top-1642173651 .shape-fill {
    fill: #DA0037;
}
h1{
  color: #750550;
}
.MeImage{
    height: 500px;
    width: 500px;
    margin: 20% 1% 3% 20%;
    padding: 2% 2% 2% 2%;

}