@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@600&display=swap);

@tailwind base;

@tailwind components;

@tailwind utilities;

h1 {
  font-size: 3rem;
  font-family: "Prompt", sans-serif;
}
.TimeLineText {
  margin-left: 2%;
  font-size: 50px;
  font-weight: bolder;
}
p {
  font-size: 1.5rem;
}
body {
  margin: 0;
  font-family: "Prompt", sans-serif;
  color: white;
  background: #000000;
}
section {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  padding-top: 50px;
}
.blue {
  background: #3c31dd;
}
.dark {
  background: #000000;
}
.pink {
  background: #ff0066;
}

.curve {
  position: absolute;
  height: 250px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  transform: translate(85%, 60%);
  background-color: #000000;
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #3c31dd;
  transform: translate(-4%, 40%);
  z-index: -1;
}

.spacer {
  aspect-ratio: 900/600;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer1 {
  background-image: url(/static/media/layer1.f1fa3df5.svg);
}
.layer2 {
  background-image: url(/static/media/layer2.05c7a47a.svg);
}

.flip {
  transform: rotate(180deg);
}
.aboutLayer {
  background-image: url(/static/media/About.6fc2e0d9.svg);
}

.custom-shape-divider-bottom-1642085737 {
  margin-top: 2%;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1642085737 svg {
  position: relative;
  display: block;
  width: calc(134% + 1.3px);
  height: 287px;
}

.custom-shape-divider-bottom-1642085737 .shape-fill {
  fill: #ff0066;
}

.custom-shape-divider-top-1642097561 {
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: -1;
}

.custom-shape-divider-top-1642097561 svg {
    position: relative;
    display: block;
    width: calc(107% + 1.3px);
    height: 216px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1642097561 .shape-fill {
    fill: #9E0861;
}
h2{
    color: white;
}
/*TEXT*/
.MainHeading{
    margin: 10% 10% 2% 10%;
}
.Hello{
    font-size: 60px;
    word-wrap: break-word;
}
.Name{
    font-size: 60px;
    color: white;
    word-wrap: break-word;
    line-height: 1;
    margin-top: 5%;  
}

.FirstText{
    color: #FF4C29;
    margin-top: 7%;  
}
.SecondtEXT{
    color: #3E065F;
    margin-top: 7%;  
}
.ThirdText{
    color: #FF2E63;
    margin-top: 7%;  
}
.FourthText{
    color: #FFF338;
    margin-top: 7%;  
}
.FifthText{
    color: #FF008E;
    margin-top: 7%;  
}
.SixthText{
    color: #FF0000;
    margin-top: 7%;  
}
.SeventhText{
    color: #C0FEFC;
    margin-top: 7%;  
}
.Sub{
    font-size: 50px;
    padding: 0% 1% 1% 1%;
}
.SocialIcons{
 padding: 2% 2% 2% 0%;
}.SocialIconsImages{
    padding: 1% 1% 1% 1%;
}
.MainImage{
    margin: 10% auto 10% auto;
    width: 450px;
    height: 450px;
}


.AboutSubText{
    color: whitesmoke;
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
  word-wrap: break-word;
}
p{
    color: whitesmoke;
}
.AnimatedText{
    color: #F70776;
}
.ProjectArea{
    margin: 3% 10% 2% 10%;
}
.ProjectImage{
    width: 350px;
    height: 350px;
}
.ProjectsTitle{
    margin: 15% 3% 2% 15%;
    color: #FF008E;
    font-size: 70px;
    font-weight: bolder;
}
.SubProject{
    line-height: 1;
    word-wrap: break-word;
    color: white;
    font-weight: bold;
    margin: 10% 2% 2% 15%;
}
.ProjectAnimatedText{
    color: #9E0861;
}
.ProjectButton{
    text-align: center;
    margin: 2% 3% 2% 15%;
}
.custom-shape-divider-bottom-1642097969 {
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-top: 2%;
}

.custom-shape-divider-bottom-1642097969 svg {
    position: relative;
    display: block;
    width: calc(107% + 1.3px);
    height: 216px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1642097969 .shape-fill {
    fill: #511845;
}

body{
    background: #000000;
}

.D{
    margin: 30% 3% 0% 5%; 
}
.ABoutText{
    margin: 15% 3% 3% 10%;
    font-weight: bolder;
    font-size: 70px;
    word-wrap: break-word;
}
.AboutMEOne{
    color: #A8FF3E;
}
.AboutMETwo{
    color: #FC5404 ;
}
.AboutMeThree{
    color: #610094;
}
.AboutMeFour{
    color: #DA0037;
}
.AboutMeFive{
    color: #FFD700;
}
.AboutMeSix{
    color: #F70776;
    margin-left: 2%;
}
.AboutMeSeven{
    color: #900C3F;
}
@media (max-width: 400px) {
    .ABoutText{
        word-wrap: break-word;
    }
    .AboutMePara{
        word-wrap: break-word;
    }
}
.ABoutText:hover{
    color: #6867AC;
}
.AnimatedText{
    color: #900C3F;
}
.SubText{
    margin: 10% 3% 2% 10%;
    font-weight: bold;
    font-size: 30px;
    line-height: 1;
    word-wrap: break-word;
}
.AboutMePara{
    margin: 5% 3% 2% 10%;
    font-size: 20px;
    word-wrap: break-word;
    line-height: 1;
    color: white;

}
.SubAbout{
    margin: 2% 3% 2% 10%;
    opacity: 0.5;
    font-size: 17px;
}
.AboutBlocks{
    margin: 10% 2% 2% 9%;
    z-index: 0;
}
.Blockone{
    margin: 2% 2% 1% 2%;
    padding: 2% 2% 2% 2%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: -5px -5px 10px rgba(255, 255,255,0.2),
                 15px 15px 20px rgba(0, 0,0,0.8);
    background: #F9F7F7;

}
.Blocktwo{
    margin: 2% 2% 1% 2%;
    background: #F9F7F7;
    padding: 2% 2% 2% 2%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: -5px -5px 10px rgba(255, 255,255,0.2),
    15px 15px 20px rgba(0, 0,0,0.8);
background: #F9F7F7;
}
.Blockthree{
    margin: 2% 2% 1% 2%;
    background: #F9F7F7;
    padding: 2% 2% 2% 2%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: -5px -5px 10px rgba(255, 255,255,0.2),
    15px 15px 20px rgba(0, 0,0,0.8);
    background: #F9F7F7;
}
.blockText{
    color:#F9F7F7;
    margin: 15% 2% 9% 2%;
}
.blockTextone{
    color: #F9F7F7;
    margin: 15% 2% 9% 2%;
}
.AboutIcons{
   margin: 2% 15% 2% 30%;
   color: #A8FF3E;
   width: 80px;
   height: 80px;
}


.custom-shape-divider-bottom-1642173415 {
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-top: 2%;

}

.custom-shape-divider-bottom-1642173415 svg {
    position: relative;
    display: block;
    width: calc(138% + 1.3px);
    height: 244px;
}

.custom-shape-divider-bottom-1642173415 .shape-fill {
    fill: #FF008E;
}



.custom-shape-divider-top-1642173651 {
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 0;
}

.custom-shape-divider-top-1642173651 svg {
    position: relative;
    display: block;
    width: calc(145% + 1.3px);
    height: 142px;
}

.custom-shape-divider-top-1642173651 .shape-fill {
    fill: #DA0037;
}
h1{
  color: #750550;
}
.MeImage{
    height: 500px;
    width: 500px;
    margin: 20% 1% 3% 20%;
    padding: 2% 2% 2% 2%;

}
body {
    margin: 0;
    font-family: Helvetica, sans-serif;
  }
  
.t{
  color: white;
}
.t:hover{
  color: black;
}
  
  /* header */
  
  .header {
    background-color: transparent;
    position: fixed;
    width: 100%;
    z-index: 3;
  }
  
  .header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: transparent;
  }
  
  .header li a {
    display: block;
    padding: 5px 5px;
    border-right: 1px solid transparent;
    text-decoration: none;
  }
  
  .header li a:hover,
  .header .menu-btn:hover {
    background-color: transparent;
  }
  
  .header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
  }
  
  /* menu */
  
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
  }
  
  /* menu icon */
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
  }
  
  .header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }
  
  .header .menu-icon .navicon:before {
    top: 5px;
  }
  
  .header .menu-icon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }
  
  .header .menu-btn:checked ~ .menu {
    max-height: 240px;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  
  /* 48em = 768px */
  
  
  @media (min-width: 600px) {
    .header li {
      float: left;
    }
    .header li a {
      padding: 10px 10px;
    }
    .header .menu {
      clear: none;
      float: right;
      max-height: none;
    }
    .header .menu-icon {
      display: none;
    }
  }

  
  
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
}

.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background: #f4eeff;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.TimeLinebox {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.TimeLinebox.leftBox {
  left: 0;
}

.TimeLinebox.rightBox {
  left: 50%;
}

.TimeLinebox::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  border: 2px solid #f4eeff;
  border-radius: 16px;
  z-index: 1;
}

.TimeLinebox.rightBox::after {
  left: -8px;
}

.TimeLinebox::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: #f4eeff;
  z-index: 1;
}

.TimeLinebox.rightBox::before {
  left: 8px;
}

.TimeLinebox .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #f4eeff;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.TimeLinebox.leftBox .date {
  right: -130px;
}

.TimeLinebox.rightBox .date {
  left: -130px;
}

.TimeLinebox .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #ff2e63;
  border: 2px solid #f4eeff;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: #f4eeff;
  z-index: 1;
}

.TimeLinebox.leftBox .icon {
  right: 56px;
}

.TimeLinebox.rightBox .icon {
  left: 56px;
}

.TimeLinebox .content {
  padding: 30px 90px 30px 30px;
  background: #ff2e63;
  position: relative;
  border-radius: 0 500px 500px 0;
}

.TimeLinebox.rightBox .content {
  padding: 30px 30px 30px 90px;
  border-radius: 500px 0 0 500px;
}

.TimeLinebox .content h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: normal;
  color: #f4eeff;
}

.TimeLinebox .content p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

@media (max-width: 767.98px) {
  .timeline::after {
    left: 90px;
  }

  .TimeLinebox {
    width: 100%;
    padding-left: 120px;
    padding-right: 30px;
  }

  .TimeLinebox.rightBox {
    left: 0%;
  }

  .TimeLinebox.leftBox::after,
  .TimeLinebox.rightBox::after {
    left: 82px;
  }

  .TimeLinebox.leftBox::before,
  .TimeLinebox.rightBox::before {
    left: 100px;
    border-color: transparent transparent transparent;
  }

  .TimeLinebox.leftBox .date,
  .TimeLinebox.rightBox .date {
    right: auto;
    left: 0px;
  }

  .TimeLinebox.leftBox .icon,
  .TimeLinebox.rightBox .icon {
    right: auto;
    left: 146px;
  }

  .TimeLinebox.leftBox .content,
  .TimeLinebox.rightBox .content {
    padding: 30px 30px 30px 90px;
    border-radius: 500px 0 0 500px;
  }
}

h1 {
  font-size: 3rem;
  font-family: "Prompt", sans-serif;
}
.TimeLineText {
  margin: 5% 0% 2% 5%;
  font-size: 50px;
  font-weight: bolder;
  color: #000000;
}
.TimeLineSubText {
  margin: 5% 0% 2% 5%;
}
.Tanimation {
  color: #000000;
}
p {
  font-size: 1.5rem;
}
body {
  font-family: "Prompt", sans-serif;
  color: white;
  background: #000000;
  overflow-x: hidden;
}
section {
  position: relative;
  display: flex;
  min-height: 500px;
  padding-top: 50px;
}
.blue {
  background: #ff0066;
}
.dark {
  background: #000000;
}

.Certificates {
  text-align: center;
  margin: 5% 0% 5% 0%;
  font-weight: bolder;
  font-size: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.ResumeImage {
  width: 300px;
  height: 300px;
  margin-top: 1%;
}

.curve {
  position: absolute;
  height: 250px;
  width: 100%;
  bottom: 0;
  text-align: center;
  z-index: 0;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  transform: translate(85%, 60%);
  background-color: #000000;
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #ff0066;
  transform: translate(-4%, 40%);
}

@media (max-width: 600px) {
  .Certificates {
    font-size: 30px;
  }
}

.custom-shape-divider-top-1642690743 {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1642690743 svg {
  position: relative;
  display: block;
  width: calc(140% + 1.3px);
  height: 188px;
}

.custom-shape-divider-top-1642690743 .shape-fill {
  fill: #FFF323;
}
.ContactMetEXT{
  margin: 3% 5% 3% 20%;
}
.ContactMe{
  margin: 2% 10% 1% 15%;
  height: 200px;
  width: 200px;
}
.ContachHeading{
  margin-top: 3%;
}
.ConnectedTextOne{
  color: #FFC900;
  font-weight: bolder;
  font-size: 40px;
  
}
.ConnectedTextTwo{
  color: #4E9F3D;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextThree{
  color: #E60965;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextFour{
  color: #AEFEFF;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextFive{
  color: #FC5404;
  margin-left: 3%;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextSix{
  color: #8C0000;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextSeven{
  color: #2A1A5E;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextEight{
  color: #DA0037;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextNine{
  color: #6A097D;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextNine{
  color: #F1EBBB;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextTen{
  color: #E60965;
  font-weight: bolder;
  font-size: 40px;

}
.ConnectedTextEleven{
  color: #FFC900;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextTwleve{
  color: #3E065F;
  font-weight: bolder;
  font-size: 40px;
}
.ConnectedTextThird{
  color: #77D970;
  font-weight: bolder;
  font-size: 40px;
}
.SocialIcons{
  padding: 2% 2% 2% 0%;
 }.SocialIconsImages{
     padding: 1% 1% 1% 1%;
 }
 /* hh */

.form-group{
  margin: 2% 2% 2% 2%;
  border-radius: 20px;
  box-shadow: white;
}
.ContactMeSubtext{
  line-height: 1;
  word-wrap: break-word;
  font-size: 30px;
  font-weight: bold;
  color: white;
  margin: 7% 5% 0% 0%;
}
.ContactAnimatedText{
  color: #E60965;
}




.custom-shape-divider-bottom-1642690813 {
margin-top: 2%;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1642690813 svg {
  position: relative;
  display: block;
  width: calc(140% + 1.3px);
  height: 239px;
}

.custom-shape-divider-bottom-1642690813 .shape-fill {
  fill: #980F5A;
}
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}


.container2{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 100px;
  z-index: -1;
}


.contact-box{
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07), 
  0 4px 8px rgba(0,0,0,0.07), 
  0 8px 16px rgba(0,0,0,0.07),
  0 16px 32px rgba(0,0,0,0.07), 
  0 32px 64px rgba(0,0,0,0.07);
}


.right{
	padding: 25px 40px;
}

.ContactUs{
	position: relative;
	padding: 0 0 10px;
	margin-bottom: 10px;
}

.ContactUs:after{
	content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
    background-color: orange;
}

.field{
	width: 100%;
	border: 2px solid rgba(0, 0, 0, 0);
	outline: none;
	background-color: rgba(230, 230, 230, 0.6);
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	margin-bottom: 22px;
	transition: .3s;
  line-height: 1;
  word-wrap: break-word;
  color: #000000;

}

.field:hover{
	background-color: rgba(0, 0, 0, 0.1);
}

textarea{
	min-height: 150px;
  line-height: 1;
  word-wrap: break-word;
  color: #000000;
}

.btn{
	width: 100%;
	padding: 0.5rem 1rem;
  background: #9C0F48;
	color: #9C0F48;
	font-size: 1.1rem;
	border: none;
	outline: none;
	cursor: pointer;
	transition: .3s;
}

.btn:hover{
    background-color: #9C0F48;
}

.field:focus{
    border: 2px solid rgba(30,85,250,0.47);
    background-color: #fff;
}

.SocialIcons{
    padding: 2% 2% 2% 0%;
}
.SocialIconsImages{
       padding: 1% 1% 1% 1%;
}
.custom-shape-divider-top-1643035441 {
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1643035441 svg {
    position: relative;
    display: block;
    width: calc(103% + 1.3px);
    height: 185px;
}

.custom-shape-divider-top-1643035441 .shape-fill {
    fill: #020350;
}


.WebMainText{
    font-size: 40px;
    font-weight: bolder;
    color: white;
    margin: 10% 10% 5% 10%;
   
}
.SubprojectText{
    margin: 0% 10% 1% 10%;
    font-size: 25px;
    line-height: 1;
    word-wrap: break-word;
}
.WebSpanText{
    color: #480032;
}
.WebImage{
    margin-top: 10%;
    margin-bottom: 5%;
    width: 300px;
    height: 300px;
}
.ProjectBlocks{
    margin: 2% 10% 2% 10%;
}
@media (max-width: 600px) {
    .ProjectBlocks{
   margin: 2% 25% 2% 20%;
    }
}
@media (max-width: 475px) {
    .ProjectBlocks{
        margin: 2% 25% 2% 20%; 
    }
}
.Blocks{
    justify-content: center;
align-items: center;
max-width: 100px;
flex-wrap: wrap;
padding: 20px 0;

}
.Blocks .ProjectBox{
   position: relative;
   min-width: 300px;
   height: 350px;
   box-shadow: inset 10px 5px 20px rgba(0,0,0,0.2),
                inset -5px -5px 20px rgba(255, 255,255,0.1),
                5px 5px 30px rgba(0,0,0,0.3),
                -5px -5px 15px rgba(255, 255,255,0.1);

  border-radius: 15px;
}
.Blocks .ProjectBox .SubProjectBox{
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: #000000;
    border: 2px solid #1e1f23;
    border-radius: 15px;
    box-shadow: 0 20px 50px rgba(0,0,0,0.5);
    transition: 0.5s;

}
.Blocks .ProjectBox .SubProjectBox:hover{
    transform: translateY(-50px);
    background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);

}
.Blocks .ProjectBox .SubProjectBox .ProjectContent{
  text-align: center;
  padding: 5px;

}
a{
    margin: 2% 2% 2% 2%;
}
.AboutProject{
    line-height: 1;
    word-wrap: break-word;
    color: whitesmoke;
    font-size: 15px;
    margin-top: 5%;
}
.ProjectTitle{
    color: #a8eb12;
    margin-top: 5%;
    margin-bottom: 3%;
}
.AppTitle{
    color: orangered;
    margin-top: 5%;
    margin-bottom: 3%;
}
.Cthre{
    color: #a8eb12;
    margin-left: 5%;
}
.LinkButtons{
    margin-top: 20%;
}
.custom-shape-divider-bottom-1643035612 {
   margin-top: 10%;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1643035612 svg {
    position: relative;
    display: block;
    width: calc(103% + 1.3px);
    height: 185px;
}

.custom-shape-divider-bottom-1643035612 .shape-fill {
    fill: #480032;
}
h1 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
h2 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
a {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.custom-shape-divider-top-1643206829 {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1643206829 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 182px;
}

.custom-shape-divider-top-1643206829 .shape-fill {
  fill: #f14a16;
}
.SkillSection {
  margin: 10% 2% 20% 5%;
}
.Heading {
  font-size: 70px;
  color: white;
  word-wrap: break-word;
  margin: 5% 3% 2% 5%;
}
.SkillsSubPara {
  margin: 10% 3% 2% 5%;
  font-size: 20px;
  word-wrap: break-word;
  line-height: 1;
  color: white;
}
.Cone {
  color: #ff0000;
}
.Ctwo {
  color: #fbff00;
}
.Cthree {
  color: #35013f;
}
.Cfour {
  color: #1fffff;
}
.Cfive {
  color: #000d6b;
}
.Csix {
  color: #ff0000;
}
.Cspace {
  color: #72147e;
}
.Skill {
  width: 400px;
  height: 400px;
  margin-bottom: 30%;
}
.Languages {
  margin: 20% 2% 5% 5%;
  line-height: 1;
  word-wrap: break-word;
}
.LangaugeUImage {
  width: 400px;
  height: 400px;
  margin: 10% 2% 5% 10%;
}
.Frameworks {
  margin: 15% 2% 5% 5%;
  line-height: 1;
  word-wrap: break-word;
}
.FrameworkImage {
  width: 400px;
  height: 400px;
  margin: 15% 2% 5% 10%;
}
.Skillsbar {
  margin: 5% 2% 20% 10%;
}
@keyframes load {
  from {
    width: 0%;
  }
}
.SkillText {
  margin: 2% 2% 2% 2%;
  color: #fff;
}
.bar {
  background-color: #000000;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.bar::before {
  content: attr(data-skill);
  background-color: #fbff00;
  display: inline-block;
  padding: 5px 0 5px 10px;
  border-radius: inherit;
  animation: load 2s 0s;
  -webkit-animation: load 2s 0s;
  -moz-animation: load 2s 0s;
  -o-animation: load 2s 0s;
}

.bar.front::before {
  background-color: #e60965;
}
.bar.back::before {
  background-color: #f14a16;
}
.bar.colour2::before {
  background-color: #432160;
}
.bar.colour3::before {
  background-color: #1fffff;
}
.bar.colour4::before {
  background-color: #8e05c2;
}
.bar.colour5::before {
  background-color: #ff0000;
}
.bar.colour6::before {
  background-color: #35013f;
}
.bar.colour7::before {
  background-color: #000d6b;
}
.bar.learning::before {
  width: calc(20% - 10px);
}
.bar.basic::before {
  width: calc(40% - 10px);
}
.bar.intermediate::before {
  width: calc(100% - 30%);
}
.bar.advanced::before {
  width: calc(100% - 10%);
}
.bar.expert::before {
  width: calc(100% - 0%);
}
.bar.Best::before {
  width: calc(100% - 10px);
}
.bar.ametuer::before {
  width: calc(100% - 70%);
}

.custom-shape-divider-bottom-1643207379 {
  margin-top: 2%;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1643207379 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 182px;
}

.custom-shape-divider-bottom-1643207379 .shape-fill {
  fill: #e60965;
}

#Rocket{
    width: 400px;
    height: 400px;
    margin: 3% auto;
}
.LandingIntro{
    text-align: center;
}
.GreetText{
    margin-top: 3%;
   text-align: center;
   font-weight: bolder;
   color: #EC255A;
   font-size: 60px;
}
.SubGreetText{
  margin: 0 10% 2% 10%;
  font-weight: bolder;
  color: #FF5126;
}
