*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}


.container2{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 100px;
  z-index: -1;
}


.contact-box{
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07), 
  0 4px 8px rgba(0,0,0,0.07), 
  0 8px 16px rgba(0,0,0,0.07),
  0 16px 32px rgba(0,0,0,0.07), 
  0 32px 64px rgba(0,0,0,0.07);
}


.right{
	padding: 25px 40px;
}

.ContactUs{
	position: relative;
	padding: 0 0 10px;
	margin-bottom: 10px;
}

.ContactUs:after{
	content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
    background-color: orange;
}

.field{
	width: 100%;
	border: 2px solid rgba(0, 0, 0, 0);
	outline: none;
	background-color: rgba(230, 230, 230, 0.6);
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	margin-bottom: 22px;
	transition: .3s;
  line-height: 1;
  word-wrap: break-word;
  color: #000000;

}

.field:hover{
	background-color: rgba(0, 0, 0, 0.1);
}

textarea{
	min-height: 150px;
  line-height: 1;
  word-wrap: break-word;
  color: #000000;
}

.btn{
	width: 100%;
	padding: 0.5rem 1rem;
  background: #9C0F48;
	color: #9C0F48;
	font-size: 1.1rem;
	border: none;
	outline: none;
	cursor: pointer;
	transition: .3s;
}

.btn:hover{
    background-color: #9C0F48;
}

.field:focus{
    border: 2px solid rgba(30,85,250,0.47);
    background-color: #fff;
}
