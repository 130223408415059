#Rocket{
    width: 400px;
    height: 400px;
    margin: 3% auto;
}
.LandingIntro{
    text-align: center;
}
.GreetText{
    margin-top: 3%;
   text-align: center;
   font-weight: bolder;
   color: #EC255A;
   font-size: 60px;
}
.SubGreetText{
  margin: 0 10% 2% 10%;
  font-weight: bolder;
  color: #FF5126;
}